jQuery(document).ready(function ($) {
   const connectModal = "#connect-modal";
   let shown_connect = Cookies.get('shown_connect');

   if (shown_connect === undefined) {
       Cookies.set('shown_connect', 'yes', { expires: 7 });
       $(connectModal).foundation("open");
   }

    $( ".connect-trigger a" ).click(function(e) {
        e.preventDefault();
        $(connectModal).foundation("open");
    });
});