jQuery(document).ready(function ($) {
    const event_series_slider = $(".bpo-event-series-slider");

    if (event_series_slider.length) {

        event_series_slider.slick({
            slidesToShow: 3,
            prevArrow: $('.prev-event-series'),
            nextArrow: $('.next-event-series'),
            responsive: [{
                breakpoint: 1024,
                settings: {
                    slidesToShow: 3
                }
            }, {
                breakpoint: 800,
                settings: {
                    slidesToShow: 2
                }
            }, {
                breakpoint: 400,
                settings: {
                    slidesToShow: 1
                }
            }]
        });

    }

});