jQuery(document).ready(function ($) {
    const featured_events_wrapper = $('.bpo-featured-events'),
        featured_events_slider = $(".bpo-featured-events-slider");

    if (featured_events_wrapper.length) {

        // Custom function showing current slide
        let $status = $('.pagingInfo');
        featured_events_slider.on('init reInit afterChange', function (event, slick, currentSlide, nextSlide) {
            // CurrentSlide is undefined on init -- set it to 0 in this case (currentSlide is 0 based)
            let i = (currentSlide ? currentSlide : 0) + 1;
            $status.text(i + '/' + slick.slideCount);
        });

        featured_events_slider.slick({
            slidesToShow: 1,
            fade: true,
            prevArrow: $('.prev-featured-event'),
            nextArrow: $('.next-featured-event'),
            customPaging : function(slider, i) {
                let thumb = $(slider.$slides[i]).data();
                return '<a>'+i+'</a>';
            },
        });

        featured_events_slider.on('beforeChange', function(event, slick, currentSlide, nextSlide){
            let elSlide = jQuery(slick.$slides[nextSlide]);
            let image = elSlide.find('.featured-event').data('bg-image');
            featured_events_wrapper.css('background-image', 'url(' + image + ')');
        });

        // Set initial background
        let bg = featured_events_slider.find('.slick-current .featured-event').data('bg-image');
        featured_events_wrapper.css('background-image', 'url(' + bg + ')');

    }

});