jQuery(document).ready(function ($) {
    let ajax_wrapper = '.ajax-wrapper';
    if ($(ajax_wrapper).length) {
        $(ajax_wrapper).each(function () {
            let thisContent = this.id;
            if (thisContent === 'schedule-block') {
                let event_category = $('.schedule-category').html();
                if (!event_category) {
                    event_category = 0;
                }
                $.post(
                    boxy_js_vars.ajax_url,
                    {
                        action: 'espresso_schedule_block',
                        'block': 'schedule-block',
                        // 'first_day': false,
                        'category': event_category
                    },
                    function (newHTML) {
                        $('#schedule-block').html(newHTML);
                    }
                );
            }
        });
    }

    if ($('#schedule-block')) {

        let currentlyLoadingSchedule = false;

        $('.fg-schedule-week-change').click(function (e) {

            e.preventDefault();

            if (!currentlyLoadingSchedule) {

                let event_category = $('.schedule-category').html();
                if (!event_category) {
                    event_category = 0;
                }

                currentlyLoadingSchedule = true;
                $('#schedule-block').html('<div class="spinner"></div>');
                // $('.spinner').spin('espresso');

                let weekToLoad = $(this).attr('data');

                $.post(
                    boxy_js_vars.ajax_url,
                    {
                        action: 'espresso_schedule_block',
                        'block': 'schedule-block',
                        'first_day': weekToLoad,
                        'category': event_category
                    },
                    function (newHTML) {

                        $('#schedule-block').html(newHTML);

                        let firstDay = new Date(weekToLoad);
                        let nextWeek = new Date(firstDay.getTime() + 7 * 24 * 60 * 60 * 1000);
                        let lastWeek = new Date(firstDay.getTime() - 7 * 24 * 60 * 60 * 1000);

                        let lastWeekYear = lastWeek.getFullYear();
                        let lastWeekDay = lastWeek.getDate();
                        let lastWeekMonth = lastWeek.getMonth() + 1;

                        let nextWeekYear = nextWeek.getFullYear();
                        let nextWeekDay = nextWeek.getDate();
                        let nextWeekMonth = nextWeek.getMonth() + 1;

                        $('.fg-schedule-prev-week').attr('data', lastWeekYear + '/' + lastWeekMonth + '/' + lastWeekDay);
                        $('.fg-schedule-next-week').attr('data', nextWeekYear + '/' + nextWeekMonth + '/' + nextWeekDay);
                        currentlyLoadingSchedule = false;
                    }
                );

            }

        })
    }
});
